import { ComponentPropsWithoutRef, FC } from 'react'

type GradientStyles = FC<Omit<ComponentPropsWithoutRef<'svg'>, 'style'> & { className?: string }>

export const Gradient: GradientStyles = ({ className, ...props }) => (
  <svg
    width="1920"
    height="1080"
    viewBox="0 0 1920 1080"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="gradient"
    className={className}
    {...props}
  >
    <path opacity="0.3" d="M1920 0H0V1080H1920V0Z" fill="url(#paint0_radial_5_318)" />
    <path opacity="0.3" d="M1920 0H0V1080H1920V0Z" fill="url(#paint1_radial_5_318)" />
    <defs>
      <radialGradient
        id="paint0_radial_5_318"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(760.916 571.975) scale(548.511 509.354)"
      >
        <stop stopColor="#2A58FF" />
        <stop offset="0.8421" stopColor="#D06AFF" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint1_radial_5_318"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(1285.01 567.374) scale(510.07 473.657)"
      >
        <stop stopColor="#ADAAFF" />
        <stop offset="0.835" stopColor="#3FA1FF" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
)
