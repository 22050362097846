export const d = {
  title: 'tensor-website-landing:how-it-works.title',
  sub: 'tensor-website-landing:how-it-works.sub',
  taskTitle: 'tensor-website-landing:how-it-works.task-title',
  taskSub: 'tensor-website-landing:how-it-works.task-sub',
  connectionTitle: 'tensor-website-landing:how-it-works.connection-title',
  connectionSub: 'tensor-website-landing:how-it-works.connection-sub',
  dataTitle: 'tensor-website-landing:how-it-works.data-title',
  dataSub: 'tensor-website-landing:how-it-works.data-sub',
  cta: 'tensor-website-landing:how-it-works.cta',
  taskLayerImgAlt: 'tensor-website-landing:seo.task-layer-img-alt',
  connectionLayerImgAlt: 'tensor-website-landing:seo.connection-layer-img-alt',
  dataLayerImgAlt: 'tensor-website-landing:seo.data-layer-img-alt',
}
