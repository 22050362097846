import clsx from 'clsx'
import { PropsWithChildren, forwardRef } from 'react'
import { useObjectRef } from 'react-aria'
import { Link, LinkProps } from 'react-aria-components'
import { TestProps } from 'typ'
import {
  Contrast,
  Variant,
  anchorCx,
  focusVisibleCx,
  hoveredCx,
  pressedCx,
  wrapperCx,
} from './Button.css'
import { ButtonIconProps, CoreTensorButtonProps } from './types'
import { getButtonIconSize } from './utils/get-button-icon-size'
import { getButtonLayout } from './utils/get-button-layout'
import { getComputedClassName } from './utils/get-computed-class-name'

export type CoreLinkButtonProps = LinkProps & PropsWithChildren & TestProps & CoreTensorButtonProps

export type DefaultLinkButtonProps = CoreLinkButtonProps &
  ButtonIconProps & {
    variant: Exclude<Variant, 'text' | 'solid'>
    contrast?: Contrast
  }

export type TextLinkButtonProps = CoreLinkButtonProps &
  ButtonIconProps & {
    variant: 'text'
    contrast?: Contrast
  }

export type SolidLinkButtonProps = CoreLinkButtonProps &
  ButtonIconProps & {
    variant: 'solid'
    contrast?: never
  }

export type LinkButtonProps = DefaultLinkButtonProps | TextLinkButtonProps | SolidLinkButtonProps

export const LinkButton = forwardRef<HTMLAnchorElement, LinkButtonProps>(
  (
    {
      variant,
      size = 'md',
      children,
      className,
      LeftIcon,
      RightIcon,
      color,
      width = 'auto',
      contrast = 'default',
      ...props
    },
    forwardedRef
  ) => {
    const ref = useObjectRef(forwardedRef)
    const layout = getButtonLayout({ LeftIcon, RightIcon })
    const iconSize = getButtonIconSize(size)

    return (
      <Link
        {...props}
        ref={ref}
        className={({ isPressed, isHovered, isFocused, isFocusVisible }) => {
          const computedClassName = getComputedClassName(className, {
            isFocused,
            isHovered,
            isPressed,
            isFocusVisible,
          })
          return clsx([
            wrapperCx({ size, color, variant, layout, contrast, width }),
            isHovered && hoveredCx({ variant, color, contrast }),
            isPressed && pressedCx({ variant, color }),
            isFocusVisible && isFocused && focusVisibleCx({ color }),
            anchorCx,
            computedClassName,
          ])
        }}
        data-color={color}
        data-size={size}
        data-variant={variant}
        slot={undefined}
      >
        {
          <>
            {LeftIcon && <LeftIcon size={iconSize} />}
            {children}
            {RightIcon && <RightIcon size={iconSize} />}
          </>
        }
      </Link>
    )
  }
)

LinkButton.displayName = 'Button'
