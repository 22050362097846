
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { NextPage } from 'next'
import { NextSeo } from 'next-seo'
import useTranslation from 'next-translate/useTranslation'
import Head from 'next/head'
import { buildCanonicalAndLangSeoProps } from 'src/assets/links'
import { Home as Homepage } from 'src/views/Home'

const d = {
  seoTitle: 'tensor-website-landing:seo.title',
  seoDescription: 'tensor-website-landing:seo.description',
}

const Home: NextPage = () => {
  const { t, lang } = useTranslation()
  return (
    <>
      <Head>
        <title>{t('tensor-website-common:pages.home')}</title>
      </Head>
      <NextSeo
        title={t(d.seoTitle)}
        description={t(d.seoDescription)}
        {...buildCanonicalAndLangSeoProps(lang)}
      />
      <Homepage />
    </>
  )
}

export default Home


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  